.tamainewsSingle {
  padding: 32px 0 60px;
  @include media-breakpoint-up(sm) {
    padding: 45px 0 70px;
  }
  @include media-breakpoint-up(lg) {
    padding: 65px 0 80px;
  }
  .custom-taxonomy {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
    .tamainews-category {
      display: inline-block;
      padding: 3px 5px;
      font-size: 11px;
      white-space: nowrap;
      border: solid 1px $body-color;
      line-height: 1em;
      @include media-breakpoint-up(sm) {
        padding: 4px 6px;
        font-size: 12px;
      }
      &.event {
        color: #cb0c0c;
        border: solid 1px #cb0c0c;
      }
      &.management {
        color: #80b71d;
        border: solid 1px #80b71d;
      }
      &.clinical {
        color: #e59a48;
        border: solid 1px #e59a48;
      }
      &.product {
        color: #5da2da;
        border: solid 1px #5da2da;
      }
    }
  }
}
