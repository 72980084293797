.toggleMenu {
  position: fixed;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: $zindex-fixed + 1;
  min-width: $headerHeightSM;
  min-height: $headerHeightSM;
  @include media-breakpoint-up(sm) {
    min-width: $headerHeightLG;
    min-height: $headerHeightLG;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
  .toggleMenuBtn {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    width: $headerHeightSM;
    height: $headerHeightSM;
    display: flex;
    gap: 9%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    transition: $transition-base;
    @include media-breakpoint-up(sm) {
      width: $headerHeightLG;
      height: $headerHeightLG;
    }
    cursor: pointer;
    .bar,
    &::before,
    &::after {
      width: 36%;
      height: 1px;
      background-color: $white;
      content: "";
      transition: $transition-base;
    }
    &.active {
      .bar {
        opacity: 0;
        display: none;
        position: absolute;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: 32%;
        transform: rotate(45deg);
        transform-origin: center;
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 32%;
        transform: rotate(-45deg);
        transform-origin: center;
      }
    }
  }
  .cont {
    padding-top: $headerHeightSM;
    background-color: $white;
    color: $body-color;
    position: relative;
    z-index: 1;
    width: 600px;
    height: 100vh;
    max-width: 100vw;
    overflow-y: auto;
    @include media-breakpoint-up(sm) {
      padding-top: $headerHeightLG;
    }
    .gnav {
      margin: 0;
      padding: 0 2rem;
      list-style-type: none;
      > li {
        border-bottom: 1px solid $border-color;
        > a {
          color: $body-color;
          text-decoration: none;
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
          gap: 10px;
          width: 100%;
          padding: 1rem 0;
          &::before {
            font-family: "Font Awesome 5 Pro";
            content: "\f138";
            color: $primary;
            font-weight: 900;
          }
          &.subnav {
            position: relative;
            &::after {
              position: absolute;
              top: 0;
              right: 20px;
              transform: rotate(180deg);
              font-size: 15px;
              font-weight: normal;
              align-self: center;
              padding: 1rem 0;
              margin-left: 15px;
              font-family: "Font Awesome 5 Pro";
              content: "\f078";
              color: $body-color;
              transition: $transition-base;
            }
            &.collapsed {
              &::after {
                transform: rotate(0);
                font-size: 15px;
                font-weight: normal;
                align-self: center;
                margin-left: 15px;
                font-family: "Font Awesome 5 Pro";
                content: "\f078";
                color: $body-color;
              }
            }
          }
        }
        > ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          font-size: 90%;
          > li {
            border-bottom: 1px solid $border-color;
            > a {
              color: $body-color;
              text-decoration: none;
              display: flex;
              padding: 1rem 2rem;
              display: flex;
              justify-content: flex-start;
              align-items: baseline;
              gap: 10px;
              width: 100%;
              &::before {
                font-family: "Font Awesome 5 Pro";
                content: "\f138";
                color: $primary;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
