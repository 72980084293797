.articleHeader {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 36px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 45px;
  }
  .title {
    font-family: $font-family-sans-serif;
    font-weight: bold;
    font-size: 26px;
    @include media-breakpoint-up(sm) {
      font-size: 36px;
    }
  }
  .data {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .post-categories {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 14px;
      li {
        margin-right: 1rem;
        a {
          padding: 3px 15px;
          background-color: $white;
          border: 1px solid $border-color;
          color: $secondary;
          text-decoration: none;
          transition: $transition-base;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: $border-radius;
          &:hover {
            color: $white;
            background-color: $secondary;
          }
        }
      }
    }
    .date {
      margin-right: 1rem;
      color: $secondary;
      font-size: 15px;
      font-family: $headings-font-family;
    }
  }
}
.articleBody {
  max-width: 800px;
  margin: 0 auto;
  $cms-space: 50px;
  font-size: 15px;
  padding-bottom: $cms-space;
  @include media-breakpoint-up(sm) {
    padding-bottom: $cms-space * 1.5;
  }
  .leadArea {
    padding-left: 1.5rem;
    border-left: 2px solid $primary;
    margin-bottom: $cms-space;
  }
  .columnArea {
    border: 2px solid $border-color;
    padding: 1.5rem;
    margin-bottom: $cms-space;
  }
  .tags {
    margin-bottom: $cms-space;
    color: $secondary;
    display: flex;
    justify-content: flex-start;
    a {
      display: flex;
      color: $secondary;
      text-decoration: none;
      transition: $transition-base;
      margin-right: 15px;
      &::before {
        content: "#";
      }
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  h2,
  h3,
  h4,
  h5 {
    font-family: $font-family-sans-serif;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: $cms-space;
    // margin-top: $cms-space * 2;
  }
  h2 {
    padding-bottom: 1rem;
    position: relative;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(90deg, #ffa800 0%, #eb2421 100%);
      content: "";
    }
  }
  h3 {
    background-color: #f5f4f0;
    padding: 1.2rem;
  }
  h4 {
    padding-left: 1.2rem;
    border-left: 2px solid $weaken;
  }
  .lead {
    padding-left: 1.2rem;
    border-left: 3px solid $vermilion;
  }
  p {
    margin-bottom: $cms-space;
    line-height: 2;
  }
  .box {
    padding: 30px;
    margin-bottom: $cms-space;
    line-height: 2;
    border: solid 2px #eaf0f4;
  }
  .half {
    @include media-breakpoint-up(sm) {
      flex-basis: 50%;
    }
  }
  .other {
    @include media-breakpoint-up(sm) {
      flex: 1;
    }
  }
  ul,
  ol {
    margin-bottom: $cms-space;
    line-height: 1.75;
  }
  .wp-block-image {
    margin-bottom: $cms-space;
    figcaption {
      text-align: left;
    }
  }
  .wp-block-buttons {
    margin-bottom: $cms-space;
  }
  .wp-block-button__link,
  .wp-element-button {
    width: 300px;
    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
  .wp-block-embed {
    display: flex;
    flex-direction: column;
    margin-bottom: $cms-space;
    &.aligncenter {
      align-items: center;
    }
    figcaption {
      text-align: left;
    }
  }
  .wp-block-group {
    flex-direction: column;
    margin-bottom: $cms-space;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      align-items: flex-start;
      gap: 30px;
    }
    .wp-block-image,
    .wp-block-buttons,
    p {
      margin: 0;
    }
  }
  .wp-block-table {
    margin-bottom: $cms-space;
    tr {
      vertical-align: top;
      td,
      th {
        border: solid 1px #e6e6e6;
      }
      th {
        padding-left: 1em;
        padding-right: 1em;
        text-align: center;
        white-space: nowrap;
        background-color: #f7f7f7;
        @include media-breakpoint-up(sm) {
          padding-left: 2em;
          padding-right: 2em;
        }
      }
      td {
        @include media-breakpoint-up(sm) {
          padding-left: 1em;
          padding-right: 1em;
        }
      }
    }
  }
}
.articleFooter {
  max-width: 800px;
  margin: 0 auto;
  border-top: 1px solid $border-color;
  display: grid;
  grid-template:
    "... ... ..." 20px
    "prev ... next" 40px
    "... ... ..." 20px
    "index index index" 40px
    / 45% 1fr 45%;
  @include media-breakpoint-up(sm) {
    grid-template:
      "prev index next" 90px
      / 30% 1fr 30%;
  }
  .prev {
    grid-area: prev;
    display: flex;
    align-items: center;
    justify-content: center;
    // a {
    //   .icon {
    //     transition: $transition-base;
    //     margin-right: 1rem;
    //   }
    //   &:hover {
    //     .icon {
    //       transform: translateX(-3px);
    //     }
    //   }
    // }
  }
  .index {
    grid-area: index;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(sm) {
      // &::before {
      //   background-color: $border-color;
      //   width: 1px;
      //   height: 30px;
      //   content: "";
      // }
      // &::after {
      //   background-color: $border-color;
      //   width: 1px;
      //   height: 30px;
      //   content: "";
      // }
    }
    // a {
    //   .icon {
    //     width: 24px;
    //     height: 24px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     border: 1px solid $body-color;
    //     border-radius: 50%;
    //     transition: $transition-base;
    //     margin-left: 1rem;
    //   }
    //   &:hover {
    //     .icon {
    //       background-color: $body-color;
    //       color: $white;
    //     }
    //   }
    // }
  }
  .next {
    grid-area: next;
    display: flex;
    align-items: center;
    justify-content: center;
    // a {
    //   .icon {
    //     transition: $transition-base;
    //     margin-left: 1rem;
    //   }
    //   &:hover {
    //     .icon {
    //       transform: translateX(3px);
    //     }
    //   }
    // }
  }
  // a {
  //   display: flex;
  //   width: 100%;
  //   height: 100%;
  //   color: $body-color;
  //   text-decoration: none;
  //   align-items: center;
  //   justify-content: center;
  //   font-weight: bold;
  //   transition: $transition-base;
  //   padding: 20px;
  //   border: 1px solid $border-color;
  //   border-radius: $border-radius;
  //   font-size: 12px;
  //   @include media-breakpoint-up(sm) {
  //     border: none;
  //     border-radius: 0;
  //     font-size: 14px;
  //   }
  //   &:hover {
  //     text-decoration: none;
  //     background-color: $table-hover-bg;
  //   }
  // }
  &.b-none {
  }
}
