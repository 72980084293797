.productDigest {
  display: flex;
  flex-direction: column-reverse;
  max-width: 1260px;
  margin: 0 auto;
  gap: 2rem;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .cont {
    width: 100%;
    background: linear-gradient(45deg, #eb2421 0%, #ffa800 100%);
    color: $white;
    padding: 45px 6vw;
    p {
      margin-bottom: 0;
      line-height: 1.75;
    }
    @include media-breakpoint-up(sm) {
      width: 420px;
      height: auto;
      padding: 45px;
      p {
        margin-bottom: 2rem;
      }
    }
  }
  .img {
    width: 100%;
    text-align: center;
    @include media-breakpoint-up(sm) {
      flex: 1;
    }
    @include media-breakpoint-up(lg) {
      flex: 0;
      flex-basis: calc(100% - 420px);
    }
  }
}
