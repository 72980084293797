.formTable {
  font-size: 15px;
  width: 100%;
  dl {
    display: block;
    margin: 0;
    padding: 0;
    dt {
      display: block;
      text-align: left;
      margin: 0;
      padding: 10px 0;
      padding-bottom: 0;
      .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        .title {
          flex: 1;
          font-weight: bold;
        }
        .label {
          .badge {
            padding: 5px 15px;
            &.bg-secondary {
              background-color: $gray-400 !important;
            }
          }
        }
      }
    }
    dd {
      padding: 10px 0;
      display: block;
      text-align: left;
      border-bottom: 1px solid $gray-300;
      margin: 0;
      .checkboxWrap {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        @include media-breakpoint-up(sm) {
          flex-direction: row;
          align-items: center;
          gap: 1rem;
        }
      }
      .mwform-checkbox-field,
      .mwform-radio-field {
        &.horizontal-item {
          margin: 0;
        }
        label {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          white-space: nowrap;
        }
      }
    }
  }
  @include media-breakpoint-up(sm) {
    display: table;
    dl {
      display: table-row;
      dt {
        border-bottom: 1px solid $gray-300;
        vertical-align: top;
        display: table-cell;
        padding: 1rem 0;
        padding-right: 2rem;
        width: 1%;
        white-space: nowrap;
        .inner {
          padding: $input-padding-y 0;
        }
        .title {
        }
      }
      dd {
        display: table-cell;
        border-bottom: 1px solid $gray-300;
        padding: 1rem 0;
        vertical-align: middle;
        .form-control,
        .form-select {
          margin: 0;
        }
      }
    }
  }
}
.zipform {
  width: 180px;
}
