.anchorLinks {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    a {
      width: 100%;
      text-decoration: none;
      background-color: #f5f4f0;
      display: flex;
      gap: 1rem;
      color: $body-color;
      padding: 20px 10px;
      justify-content: space-between;
      align-items: center;
      position: relative;
      font-size: 12px;
      font-family: $font-family-serif;
      white-space: nowrap;
      @include media-breakpoint-up(sm) {
        padding: 20px 30px;
        justify-content: center;
        font-size: 15px;
      }
      @include media-breakpoint-up(lg) {
        padding: 20px 45px;
        font-size: 16px;
      }
      &::after {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("../img/arrow_orange@2x.png") no-repeat;
        background-size: contain;
        transition: $transition-base;
        @include media-breakpoint-up(sm) {
          position: absolute;
          right: 1rem;
          top: calc(50% - 8px);
        }
      }
      &:hover {
        &::after {
          transform: translateY(2px);
        }
      }
    }
  }
}
