.faq {
  .faqSingle {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .header {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      color: $white;
      text-decoration: none;
      width: 100%;
      padding: 15px;
      background-color: $weaken;
      @include media-breakpoint-up(sm) {
        padding: 20px 30px;
      }
      .icon {
        font-size: 27px;
        font-weight: bold;
        margin-right: 1rem;
        font-family: $font-family-serif;
        border-right: 1px solid $white;
        padding-right: 1rem;
      }
      .title {
        flex: 1;
        font-size: 15px;
        font-weight: bold;
        margin: 0;
        font-family: $font-family-sans-serif;
        width: 100%;
        display: flex;
        align-items: center;
        @include media-breakpoint-up(sm) {
          font-size: 18px;
        }
      }
      &.collapsed {
        &::after {
          font-size: 18px;
          align-self: center;
          margin-left: 10px;
          font-family: "Font Awesome 5 Pro";
          content: "\f067";
          transition: $transition-base;
        }
      }
      &::after {
        font-size: 18px;
        align-self: center;
        margin-left: 10px;
        font-family: "Font Awesome 5 Pro";
        content: "\f068";
      }
    }
    .body {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      color: $body-color;
      text-decoration: none;
      width: 100%;
      padding: 15px;
      background-color: $white;
      margin-top: 1px;
      @include media-breakpoint-up(sm) {
        padding: 20px 30px;
      }
      .icon {
        font-size: 27px;
        font-weight: bold;
        margin-right: 1rem;
        font-family: $font-family-serif;
        color: $weaken;
      }
      .cont {
        flex: 1;
        padding: 0.5em 0;
        line-height: 2;
      }
    }
  }
}
