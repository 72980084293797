.pride {
  display: flex;
  flex-direction: column-reverse;
  @include media-breakpoint-up(sm) {
    display: block;
    position: relative;
  }
  .bg {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
    @include media-breakpoint-up(sm) {
      position: relative;
      z-index: 1;
    }
  }
  .cont {
    background: linear-gradient(-45deg, #eb2421 0%, #ffa800 100%);
    color: $white;
    width: 100%;
    padding: 3.5rem 6vw;
    .title {
      font-size: 27px;
      text-align: center;
      line-height: 1.5;
      margin-bottom: 30px;
      @include media-breakpoint-up(lg) {
        font-size: 33px;
      }
    }
    .text {
      font-size: 15px;
      line-height: 1.75;
      margin: 0;
    }
    @include media-breakpoint-up(sm) {
      padding: 45px 30px;
      position: absolute;
      z-index: 2;
      right: 6vw;
      top: 50%;
      transform: translateY(-50%);
      width: 390px;
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      padding: 90px 60px;
      width: 420px;
    }
  }
}
