.supportDigest {
  .img {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: 68%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
      @include media-breakpoint-up(sm) {
        padding-top: 50%; //高さの比率 ÷ 幅の比率 × 100
      }
      @include media-breakpoint-up(lg) {
        padding-top: 41.67%; //高さの比率 ÷ 幅の比率 × 100
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    padding: 24px 6vw;
    position: relative;
    z-index: 2;
    background-color: $white;
    @include media-breakpoint-up(sm) {
      padding: 5vw 6vw;
    }
    @include media-breakpoint-up(lg) {
      padding: 4vw 6vw;
    }
    .title {
      line-height: 1.5;
      margin-bottom: 20px;
    }
    .text {
      line-height: 2;
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
  @include media-breakpoint-up(sm) {
    position: relative;
    padding-top: 36vw;
    padding-left: 30vw;
    .img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }
    .cont {
      position: relative;
      z-index: 2;
    }
    &.reverse {
      padding-left: 0;
      padding-right: 30vw;
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 25vw;
    padding-left: 45vw;
    .img {
      width: 96vw;
    }
    &.reverse {
      .img {
        left: auto;
        right: 0;
      }
      padding-right: 45vw;
    }
  }
}
