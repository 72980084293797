.serviceArea {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: center;
    padding-left: 6vw;
  }
  .cont {
    padding: 0 6vw;
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(lg) {
      width: 45vw;
      padding: 0;
      max-width: 450px;
    }
    .text {
      margin: 0;
      line-height: 1.75;
      @include media-breakpoint-up(lg) {
        margin-bottom: 2rem;
      }
    }
  }
  .img {
    position: relative;
    z-index: 1;
    margin-top: -30px;
    overflow: hidden;
    width: 100%;
    height: auto;
    @include media-breakpoint-up(lg) {
      margin-left: -10vw;
      max-width: 1000px;
    }
    img {
      max-width: none;
      width: 120%;
      margin-left: -15%;
      @include media-breakpoint-up(sm) {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
