@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
img {
  max-width: 100%;
  height: auto;
}
#app {
  overflow-x: hidden;
}
[v-cloak] {
  display: none;
}
@import "components/header";
@import "components/toggleMenu";
@import "components/hero";
@import "components/coverImg";
@import "components/topNews";
@import "components/topLead";
@import "components/layout";
@import "components/sectionHeader";
@import "components/secondHeader";
@import "components/supportHeader";
@import "components/serviceDigest";
@import "components/aboutDigest";
@import "components/productDigest";
@import "components/recruitDigest";
@import "components/bnrKaigyoshien";
@import "components/greeting";
@import "components/serviceArea";
@import "components/pride";
@import "components/lead";
@import "components/newsList";
@import "components/lineupCard";
@import "components/ctaContact";
@import "components/summary";
@import "components/outline";
@import "components/overview";
@import "components/brandTable";
@import "components/dataBase";
@import "components/digest";
@import "components/supportDigest";
@import "components/anchorLinks";
@import "components/questionModal";
@import "components/midashi1";
@import "components/midashi2";
@import "components/midashi3";
@import "components/anchorLinks50";
@import "components/nameCard";
@import "components/article";
@import "components/coverBgImg";
@import "components/sidebarHeader";
@import "components/sideMenu";
@import "components/tagCloud";
@import "components/narrowThumbnailLink";
@import "components/imgList";
@import "components/carousels";
@import "components/responsiveTable";
@import "components/flow";
@import "components/flowSupport";
@import "components/faq";
@import "components/movieBlock";
@import "components/pagination";
@import "components/formTable";
@import "components/summarySingle";
@import "components/tamainews-single";
@import "components/tamainews-archive";
@import "components/task";
@import "components/weborderFlow";
@import "components/footer";
@import "components/pageTopBtn";
