.questionModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-fixed;
  background-color: rgba($white, 0.1);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(#eb2421 0%, #ffa800 100%);
    opacity: 0.9;
    content: "";
  }
  .cont {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 6vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    .text {
      font-size: 15px;
      margin-bottom: 40px;
      line-height: 1.75;
      @include media-breakpoint-up(sm) {
        text-align: center;
        margin-bottom: 60px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }
    }
    .questio {
      font-size: 21px;
      margin-bottom: 40px;
      font-weight: bold;
      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }
    }
    .answer {
      display: flex;
      gap: 10px;
      .btn-more {
        min-width: 120px;
        white-space: nowrap;
        @include media-breakpoint-up(sm) {
          min-width: 180px;
        }
      }
    }
  }
}
// .questionModal2 {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   z-index: $zindex-fixed;
//   background-color: rgba($white, 0.1);
//   -webkit-backdrop-filter: blur(6px);
//   backdrop-filter: blur(6px);
//   &::before {
//     position: absolute;
//     z-index: 1;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: linear-gradient(90deg, #ffa800 0%, #eb2421 100%);
//     opacity: 0.9;
//     content: "";
//   }
//   .cont {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     z-index: 2;
//     padding: 6vw;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     color: $white;
//     .text {
//       font-size: 16px;
//       font-weight: bold;
//       margin-bottom: 56px;
//       line-height: 1.75;
//       text-align: center;
//       @include media-breakpoint-up(sm) {
//         margin-bottom: 64px;
//       }
//       @include media-breakpoint-up(lg) {
//         font-size: 18px;
//       }
//     }
//     .answer {
//       display: flex;
//       gap: 22px;
//       @include media-breakpoint-up(sm) {
//         gap: 30px;
//       }
//       .btn-more2 {
//       }
//     }
//   }
// }
