.weborderFlow {
  .tabMenu {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    @include media-breakpoint-up(sm) {
      margin-bottom: 30px;
    }
    li {
      position: relative;
      // background: #f2f2f2;
      background: $primary;
      color: $white;
      // padding: 22px 0.5em 22px 2em;
      text-align: center;
      font-family: "Noto Serif JP", serif;
      font-size: 13px;
      width: calc((100% - 18px) / 3);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-up(sm) {
        font-size: 18px;
        // padding: 15px 0.5em 15px 2em;
        width: calc((100% - 30px) / 3);
      }
      &:first-child {
        a {
          padding: 22px 0.5em 22px 1.5em;
          @include media-breakpoint-up(sm) {
            padding: 15px 0.5em 15px 1.5em;
          }
        }
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin: auto;
      }
      &:before {
        top: 50%;
        right: -18px;
        transform: translateY(-50%);
        border-style: solid;
        border-color: transparent transparent transparent $white;
        border-width: 50px 0 50px 18px;
        z-index: 10;
        @include media-breakpoint-up(sm) {
          right: -30px;
          border-width: 40px 0 40px 30px;
        }
      }
      &:after {
        top: 50%;
        right: -15px;
        transform: translateY(-50%);
        border-style: solid;
        // border-color: transparent transparent transparent #f2f2f2;
        border-color: transparent transparent transparent $primary;
        border-width: 50px 0 50px 18px;
        z-index: 10;
        @include media-breakpoint-up(sm) {
          right: -27px;
          border-width: 40px 0 40px 30px;
        }
      }
      // &.is-active {
      //   background: $primary;
      //   color: $white;
      //   &:after {
      //     border-color: transparent transparent transparent $primary;
      //   }
      // }
      a {
        width: 100%;
        text-decoration: none;
        color: $white;
        padding: 22px 0.5em 22px 2em;
        @include media-breakpoint-up(sm) {
          padding: 15px 0.5em 15px 2em;
        }
      }
    }
  }
  .tabContents {
    width: 100%;
    // position: relative;
    margin-bottom: 30px;
    padding-top: 70px;
    margin-top: -70px;
    @include media-breakpoint-up(sm) {
      padding-top: 150px;
    margin-top: -150px;
    }
    // &::before,
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: -18px;
    //   left: 50%;
    //   -webkit-transform: translateX(-50%);
    //   transform: translateX(-50%);
    //   @include media-breakpoint-up(sm) {
    //     top: -35px;
    //   }
    // }
    // &::before {
    //   border: solid 8px transparent;
    //   border-bottom: solid 10px $primary;
    //   @include media-breakpoint-up(sm) {
    //     border: solid 15px transparent;
    //     border-bottom: solid 20px $primary;
    //   }
    // }
    // &::after {
    //   border: solid 10px transparent;
    //   border-bottom: solid 12px $white;
    //   @include media-breakpoint-up(sm) {
    //     border: solid 17px transparent;
    //     border-bottom: solid 22px $white;
    //   }
    // }
    // &.tab-1-active {
    //   &::before,
    //   &::after {
    //     left: calc(100% / 6);
    //   }
    // }
    // &.tab-3-active {
    //   &::before,
    //   &::after {
    //     left: calc(100% / 6 * 5);
    //   }
    // }
    .stepTitle {
      background-color: $primary;
      width: 100%;
      padding: 10px 0;
      font-family: "Noto Serif JP", serif;
      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }
      .stepText {
        margin: 0;
        color: $white;
        text-align: center;
      }
    }
    .stepBox {
      border: 1px solid $primary;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 25px;
      padding: 25px 20px;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        gap: 40px;
        padding: 60px 80px;
      }
      .left {
        @include media-breakpoint-up(sm) {
          flex-basis: 50%;
        }
        img {
          width: 100%;
        }
      }
      .right {
        @include media-breakpoint-up(sm) {
          flex: 1;
        }
        .stepGroup {
          margin: 0;
          padding: 0;
          .stepCont {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            padding: 16px 0;
            background-image: linear-gradient(
              to right,
              #dedede,
              #dedede 2px,
              transparent 2px,
              transparent 4px
            );
            background-size: 4px 1px;
            background-position: left bottom;
            background-repeat: repeat-x;
            @include media-breakpoint-up(sm) {
              gap: 16px;
              padding: 24px 0;
            }
            .icon {
              width: 19px;
              height: 19px;
              flex-basis: 19px;
              margin-top: -2px;
              @include media-breakpoint-up(sm) {
                width: 28px;
                height: 28px;
                flex-basis: 28px;
                margin-top: -3px;
              }
            }
            .text {
              flex: 1;
              margin: 0;
            }
            &:first-child {
              padding-top: 0;
            }
            &:last-child {
              background-image: none;
              padding-bottom: 0;
            }
          }
        }
        .img {
          padding: 13px 15px 2px;
          text-align: center;
          @include media-breakpoint-up(sm) {
            padding: 24px 24px 2px;
          }
          .image {
            font-family: "Noto Serif JP", serif;
            margin-bottom: 12px;
            @include media-breakpoint-up(sm) {
              font-size: 18px;
              margin-bottom: 19px;
            }
          }
          img {
          }
        }
      }
    }
  }
}
