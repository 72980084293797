.secondHeader {
  width: 100%;
  height: 240px;
  position: relative;
  color: $white;
  &.narrow {
    height: $headerHeightSM;
    @include media-breakpoint-up(sm) {
      height: $headerHeightLG;
    }
  }
  @include media-breakpoint-up(sm) {
    height: 400px;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    background-color: $black;
    img {
      opacity: 0.85;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    padding: calc(#{$headerHeightSM} / 2) 6vw 30px;
    @include media-breakpoint-up(sm) {
      padding: calc(#{$headerHeightSM} / 2) 6vw 0;
      justify-content: center;
    }
    .title-en {
      font-size: 34px;
      line-height: 1;
      font-family: $font-family-en;
      text-transform: uppercase;
      margin: 0;
      font-weight: normal;
      letter-spacing: 0.05em;
      @include media-breakpoint-up(sm) {
        font-size: 64px;
      }
    }
    .title-ja {
      font-size: 18px;
      font-family: $headings-font-family;
      margin: 0;
      font-weight: normal;
      @include media-breakpoint-up(sm) {
        font-size: 22px;
      }
    }
  }
}
