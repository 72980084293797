.anchorLinks50 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 5px;
  a {
    display: flex;
    flex-basis: calc((100% / 3) - 5px);
    padding: 1rem;
    justify-content: center;
    align-items: center;
    color: $body-color;
    font-weight: bold;
    border: 1px solid $weaken;
    text-decoration: none;
    transition: $transition-base;
    background-color: $white;
    gap: 10px;
    white-space: nowrap;
    @include media-breakpoint-up(sm) {
      flex: 1;
    }
    i {
      color: $weaken;
    }
    &:hover {
      background-color: $gray-100;
    }
  }
}
