.recruitDigest {
  position: relative;
  padding: 60px 0;
  background-image: url("../img/bg_recruitDigest-sm@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  @include media-breakpoint-up(sm) {
    background-image: url("../img/bg_recruitDigest.jpg");
    padding: 90px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
  .brandContainer {
    .sectionHeader {
      margin-bottom: 40px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 60px;
      }
    }
    .catchcopy {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 5px;
      .catchcopy1,
      .catchcopy2,
      .catchcopy3 {
        font-family: $font-family-en;
        color: $primary;
        background-color: $white;
        padding: 5px 10px;
        display: inline-block;
        font-size: 30px;
        font-weight: normal;
        @include media-breakpoint-up(sm) {
          font-size: 50px;
        }
      }
      @include media-breakpoint-up(sm) {
        gap: 0;
        display: grid;
        grid-template:
          "catchcopy1 catchcopy2"
          ".......... .........." 5px
          "catchcopy3 .........."
          / auto auto;
        .catchcopy1 {
          grid-area: catchcopy1;
        }
        .catchcopy2 {
          grid-area: catchcopy2;
          padding-left: 0;
        }
        .catchcopy3 {
          grid-area: catchcopy3;
        }
      }
    }
    .text {
      text-align: center;
      margin-top: 45px;
      margin-bottom: 20px;
      @include media-breakpoint-up(sm) {
        text-align: left;
        margin-top: 30px;
        margin-bottom: 45px;
        br {
          display: none;
        }
      }
    }
  }
}
