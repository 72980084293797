.flowSupport {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 15px;
  @include media-breakpoint-up(sm) {
    gap: 10px 60px;
  }
  @include media-breakpoint-up(lg) {
    gap: 30px 80px;
  }
  .flowSupportSingle {
    flex-basis: 100%;
    // &::after {
    //   position: absolute;
    //   bottom: -45px;
    //   right: 50%;
    //   width: 1px;
    //   height: 30px;
    //   background-color: $secondary;
    //   content: "";
    // }
    &:last-child {
      &::after {
        display: none;
      }
    }
    @include media-breakpoint-up(sm) {
      flex-basis: calc(50% - 30px);
      &::after {
        position: absolute;
        // bottom: auto;
        top: calc(50% + 25px);
        right: -45px;
        width: 30px;
        height: 1px;
        background-color: $secondary;
        content: "";
      }
      &:nth-child(2n) {
        &::after {
          display: none;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      flex-basis: calc((100% / 3) - 80px);
      &::after {
        right: -50px;
      }
      &:nth-child(2n) {
        &::after {
          display: block;
        }
      }
      &:nth-child(3n) {
        &::after {
          display: none;
        }
      }
    }
  }
}
.flowSupportSingle {
  padding: 30px;
  padding-top: 0;
  width: 100%;
  background-color: $white;
  position: relative;
  display: grid;
  grid-template:
    "... ... number ... ..." 90px
    "... ... ...... ... ..." 20px
    "title title title ... icon" 50px
    "... ... ...... ... ..." 20px
    "text text text text text"
    / 50px 1fr 90px 1fr 50px;
  @include media-breakpoint-up(sm) {
    grid-template:
      "... number ..." 100px
      "... ...... ..." 30px
      "... icon ..." 100px
      "... ...... ..." 30px
      "title title title"
      "... ...... ..." 30px
      "text text text"
      / 1fr 100px 1fr;
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    content: "";
    background-color: #f5f4f0;
    z-index: 1;
    @include media-breakpoint-up(sm) {
      height: 50px;
    }
  }
  .step_number {
    grid-area: number;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: #f5f4f0;
    border-radius: 50%;
    text-align: center;
    font-family: $font-family-serif;
    font-weight: bold;
    line-height: 1;
    color: $primary;
    position: relative;
    z-index: 2;
    .step {
      font-size: 15px;
      margin: 0;
    }
    .number {
      font-size: 30px;
      margin: 0;
    }
  }
  .icon {
    grid-area: icon;
    width: 100%;
    img {
    }
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .title {
    grid-area: title;
    font-size: 16px;
    padding-bottom: 1rem;
    position: relative;
    margin: 0;
    align-self: flex-end;
    @include media-breakpoint-up(sm) {
      text-align: center;
      font-size: 21px;
    }
    &::after {
      width: 28px;
      height: 1px;
      background-color: $primary;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      @include media-breakpoint-up(sm) {
        left: calc(50% - 14px);
      }
    }
  }
  .text {
    grid-area: text;
    font-size: 14px;
    line-height: 1.5;
  }
}
