.task {
  .img {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    &::before {
      content: "";
      width: 100%;
      padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .taskTitle {
    margin-bottom: 16px;
    font-size: 20px;
    font-family: "Noto Serif JP", serif;
    @include media-breakpoint-up(sm) {
      font-size: 22px;
    }
  }
  .taskText {
    margin: 0;
    line-height: 1.8;
  }
}
