.topLead {
  // background: -moz-radial-gradient(#feab01, #fea601);
  // background: -webkit-radial-gradient(#feab01, #fea601);
  // background: radial-gradient(#feab01, #fea601);
  background-image: url("../img/bg_topLead.jpg");
  background-size: 100% 100%;
  color: $white;
  padding: 60px 0;
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
  .container {
    display: grid;
    grid-template:
      "header"
      "..." 30px
      "cont"
      / 1fr;
    @include media-breakpoint-up(lg) {
      grid-template:
        "... header ... ... cont"
        / 1fr auto 15px 1fr 50%;
    }
    .header {
      grid-area: header;
      .title {
        font-size: 26px;
        margin: 0;
        text-align: center;
        line-height: 1.5;
        position: relative;
        padding-bottom: 30px;
        @include media-breakpoint-up(lg) {
          font-size: 39px;
          text-align: left;
        }
        &::before {
          position: absolute;
          bottom: 0;
          right: 50%;
          width: 20px;
          height: 4px;
          background-color: $primary;
          content: "";
          @include media-breakpoint-up(lg) {
            right: auto;
            left: 0;
          }
        }
        &::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 20px;
          height: 4px;
          background-color: $primary;
          opacity: 0.5;
          content: "";
          @include media-breakpoint-up(lg) {
            left: 20px;
          }
        }
      }
    }
    .cont {
      grid-area: cont;
      p {
        line-height: 1.8;
        margin: 0;
      }
    }
  }
}
