.ctaContact {
  position: relative;
  padding: 60px 3vw;
  background-image: url("../img/bg_contact-sm@2x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: $white;
  text-align: center;
  @include media-breakpoint-up(sm) {
    padding: 45px 3vw;
    background-image: url("../img/bg_contact@2x.jpg");
  }
  .sectionHeader {
    margin-bottom: 15px;
    .bg {
      opacity: 0.15;
    }
    .title {
      &::before,
      &::after {
        display: none;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    padding: 90px 6vw;
    &.thin {
      padding: 60px 6vw;
    }
  }
  &.support {
    color: $body-color;
    background-image: url("../img/bg_contact_support@2x.jpg");
    .sectionHeader {
      .bg {
        color: $white;
      }
    }
  }
}
