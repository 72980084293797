.leadTitle {
  text-align: left;
  font-size: 23px;
  margin-bottom: 30px;
  line-height: 1.5;
  @include media-breakpoint-up(sm) {
    font-size: 32px;
    margin-bottom: 45px;
    text-align: center;
  }
}
.leadText {
  text-align: left;
  line-height: 1.8;
  @include media-breakpoint-up(sm) {
    text-align: center;
  }
}
.leadHeader {
  padding: 0 0 30px;
  position: relative;
  margin-bottom: 30px;
  @include media-breakpoint-up(sm) {
    // padding: 0 0 45px;
    margin-bottom: 45px;
  }
  .leadTitle {
    font-size: 20px;
    margin-bottom: 0;
    text-align: center;
    @include media-breakpoint-up(sm) {
      font-size: 28px;
    }
  }
  &::before {
    position: absolute;
    bottom: 0;
    right: 50%;
    width: 20px;
    height: 4px;
    background-color: $primary;
    content: "";
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 20px;
    height: 4px;
    background-color: $primary;
    opacity: 0.5;
    content: "";
  }
}
