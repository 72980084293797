.greeting {
  position: relative;
  .greetingCover {
    position: relative;
    z-index: 1;
    .bg {
      position: relative;
      z-index: 1;
      width: 100%;
      overflow: hidden;
      &::before {
        content: "";
        width: 100%;
        padding-top: 60%; //高さの比率 ÷ 幅の比率 × 100
        display: block;
        @include media-breakpoint-up(sm) {
          padding-top: 45%;
        }
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cont {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      width: 70%;
      height: 100%;
      padding: 0 6vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      gap: 1.5rem;
      @include media-breakpoint-up(sm) {
        width: 50%;
        align-items: flex-start;
        gap: 3rem;
      }
      .name {
        padding: 0 15px;
        @include media-breakpoint-up(sm) {
          padding: 0;
        }
        img {
          height: 60px;
          width: auto;
          @include media-breakpoint-up(sm) {
            height: auto;
          }
        }
      }
    }
  }
  .greetingCont {
    padding: 2rem 6vw;
    p {
      margin-bottom: 2rem;
      line-height: 1.8;
    }
    @include media-breakpoint-up(sm) {
      position: relative;
      z-index: 2;
      padding: 5rem 6vw;
      background-color: $white;
      margin: -5rem 6vw 0;
    }
  }
}
