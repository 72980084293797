.summarySingle {
  a {
    color: $body-color;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    transition: $transition-base;
    @include media-breakpoint-up(sm) {
      flex-direction: column;
      gap: 16px;
    }
    .img {
      position: relative;
      width: 100%;
      overflow: hidden;
      flex: 1;
      &::before {
        content: "";
        width: 100%;
        padding-top: 62%; //高さの比率 ÷ 幅の比率 × 100
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text {
      flex-basis: 50%;
      @include media-breakpoint-up(sm) {
        flex: 1;
      }
      .header {
        margin-bottom: 14px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0 13px;
        flex-wrap: wrap;
        @include media-breakpoint-up(sm) {
          gap: 10px;
        }
        .date {
          font-family: "EB Garamond", serif;
          color: #888888;
          font-size: 14px;
          font-weight: 600;
          @include media-breakpoint-up(sm) {
            font-size: 15px;
          }
        }
        .custom-taxonomy {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 3px;
          flex-wrap: wrap;
          .tamainews-category {
            display: inline-block;
            padding: 3px 5px;
            font-size: 11px;
            white-space: nowrap;
            border: solid 1px $body-color;
            line-height: 1em;
            @include media-breakpoint-up(sm) {
              padding: 4px 6px;
              font-size: 12px;
            }
            &.event {
              color: #cb0c0c;
              border: solid 1px #cb0c0c;
            }
            &.management {
              color: #80b71d;
              border: solid 1px #80b71d;
            }
            &.clinical {
              color: #e59a48;
              border: solid 1px #e59a48;
            }
            &.product {
              color: #5da2da;
              border: solid 1px #5da2da;
            }
          }
        }
      }
      .title {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
          font-size: 16px;
        }
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
