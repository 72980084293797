/*----------------------------------------------------
pagination
----------------------------------------------------*/
.pagination {
  margin: 75px 0 25px;
  display: flex;
  justify-content: center;
  .screen-reader-text {
    display: none;
  }
  .nav-links {
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    .page-numbers {
      margin: 0 5px;
      display: flex;
      width: 36px;
      height: 36px;
      justify-content: center;
      align-items: center;
      color: $body-color;
      border-radius: 50%;
      background-color: #f5f4f0;
      text-decoration: none;
      transition: $transition-base;
      &.current {
        color: $white;
        background-color: $primary;
      }
      &:hover {
        color: $white;
        background-color: $primary;
      }
      &.prev,
      &.next {
        background-color: transparent;
        font-size: 24px;
        color: $primary;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
