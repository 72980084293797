.sideMenu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    border-bottom: 1px solid $white;
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      width: 100%;
      padding: 1rem;
      padding-right: 0;
      color: $body-color;
      text-decoration: none;
      transition: $transition-base;
      background-color: #f7f7f7;
      &:hover {
        background-color: #eee;
      }
      &::after {
        content: "";
        width: 20px;
        height: 1px;
        background-color: $primary;
      }
    }
  }
}
