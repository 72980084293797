.supportHeader {
  width: 100%;
  height: 240px;
  position: relative;
  color: $white;
  margin-top: $headerHeightSM;
  @include media-breakpoint-up(sm) {
    height: 560px;
    margin-top: $headerHeightLG;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    background-color: $black;
    img {
      opacity: 0.85;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 6vw;
    .title-en {
      font-size: 45px;
      line-height: 1;
      font-family: $font-family-en;
      text-transform: uppercase;
      margin: 0;
      font-weight: normal;
      letter-spacing: 0;
      display: inline-block;
      padding: 5px 10px;
      background: linear-gradient(90deg, #eb2421 0%, #ffa800 100%);
      @include media-breakpoint-up(sm) {
        font-size: 87px;
      }
    }
    .title-ja {
      font-size: 15px;
      font-family: $headings-font-family;
      margin: 0;
      font-weight: normal;
      display: inline-block;
      padding: 5px 10px;
      background: linear-gradient(90deg, #eb2421 0%, #ffa800 100%);
      @include media-breakpoint-up(sm) {
        font-size: 30px;
      }
    }
  }
}
