.nameCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $body-color;
  background-color: $white;
  padding: 10px 1.5rem;
  padding-right: 2rem;
  position: relative;
  text-decoration: none;
  transition: $transition-base;
  line-height: 1.25;
  height: 64px;
  &::after {
    font-family: "Font Awesome 5 Pro";
    content: "\f08e";
    position: absolute;
    right: 10px;
    top: 10px;
    color: $weaken;
    transition: $transition-base;
    font-size: 10px;
  }
  &:hover {
    background-color: $weaken;
    color: $white;
    &::after {
      color: $white;
    }
  }
  &.disabled {
    pointer-events: none;
    &::after {
      display: none;
    }
  }
}
