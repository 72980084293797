.hero {
  // $heroHeightSM: calc(100vh - 100px);
  $heroHeightSM: 570px;
  width: 100%;
  height: auto;
  position: relative;
  @include media-breakpoint-up(sm) {
    height: 100vh;
  }
  .bg {
    width: 100%;
    height: $heroHeightSM;
    @include media-breakpoint-up(sm) {
      height: 100vh;
    }
    position: relative;
    z-index: 1;
    .swiper-slide {
      div {
        img {
          width: 100%;
          height: $heroHeightSM;
          @include media-breakpoint-up(sm) {
            height: 100vh;
          }
          object-fit: cover;
        }
      }
    }
  }
  .cont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $heroHeightSM;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 6vw;
    @include media-breakpoint-up(sm) {
      padding: 0 6vw;
      height: 100vh;
    }
    .catch {
      margin: 0;
      padding: 0;
      font-size: 100%;
    }
  }
  .topNews {
    position: relative;
    width: 100%;
    z-index: 2;
    @include media-breakpoint-up(sm) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
