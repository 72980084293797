.dataBase {
  a {
    color: $primary;
  }
  @include media-breakpoint-up(sm) {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }
  dl.header {
    display: none;
    @include media-breakpoint-up(sm) {
      display: table-row;
      dd {
        display: table-cell;
        border: 1px solid $white;
        padding: 1rem;
        font-weight: bold;
        background-color: $weaken;
        color: $white;
      }
    }
  }
  dl.body {
    margin: 0;
    border: 1px solid $border-color;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(sm) {
      display: table-row;
      border: none;
      margin-bottom: 0;
      padding: 1rem;
    }
    dt {
      margin: 0;
      padding: 0;
      font-size: 18px;
      padding: 1rem;
      background-color: $weaken;
      color: $white;
      @include media-breakpoint-up(sm) {
        display: table-cell;
        border: 1px solid $border-color;
        padding: 1rem;
        font-size: 15px;
        font-weight: normal;
        vertical-align: middle;
        background-color: $white;
        color: $body-color;
      }
    }
    dd {
      margin: 0;
      padding: 0 1rem;
      &:nth-child(2) {
        padding-top: 1rem;
      }
      &:last-child {
        padding-bottom: 1rem;
      }
      @include media-breakpoint-up(sm) {
        display: table-cell;
        border: 1px solid $border-color;
        vertical-align: middle;
        padding: 1rem;
      }
    }
  }
}
