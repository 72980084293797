.overview {
  background-color: $white;
  .single {
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      .img {
        flex-basis: 50%;
      }
      .cont {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      &.reverse {
        flex-direction: row-reverse;
      }
    }
    .img {
      position: relative;
      width: 100%;
      overflow: hidden;
      &::before {
        content: "";
        width: 100%;
        padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cont {
      padding: 2rem 1rem;
      @include media-breakpoint-up(sm) {
        padding: 3rem 4rem;
      }
      .title {
        font-size: 18px;
        line-height: 1.5;
        font-weight: normal;
        margin-bottom: 2rem;
        padding: 1rem 0 1rem 1rem;
        border-left: 1px solid $primary;
        position: relative;
        @include media-breakpoint-up(sm) {
          font-size: 22px;
        }
        &::before,
        &::after {
          font-size: 10px;
          font-family: "Font Awesome 5 Pro";
          content: "\f067";
          width: 11px;
          height: 11px;
          background-color: $white;
          position: absolute;
          left: -6px;
          color: $primary;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &::before {
          top: 0;
        }
        &::after {
          bottom: 0;
        }
      }
      .text {
        font-size: 14px;
        line-height: 1.8;
        @include media-breakpoint-up(sm) {
          font-size: 15px;
        }
      }
    }
  }
}
