.midashi3 {
  font-size: 18px;
  line-height: 1.5;
  font-weight: normal;
  margin-bottom: 2rem;
  padding: 1rem 0 1rem 1rem;
  border-left: 1px solid $primary;
  position: relative;
  @include media-breakpoint-up(sm) {
    font-size: 22px;
  }
  &::before,
  &::after {
    font-size: 10px;
    font-family: "Font Awesome 5 Pro";
    content: "\f067";
    width: 11px;
    height: 11px;
    background-color: $white;
    position: absolute;
    left: -6px;
    color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}
