.sidebarHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 1rem;
  margin-bottom: 1rem;
  .title-en {
    font-size: 23px;
    font-weight: bold;
    font-family: $font-family-serif;
    color: $primary;
    margin: 0;
    text-transform: uppercase;
  }
  .title-ja {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
  }
}
