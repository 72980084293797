.lineupCard {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ed6103;
  background-color: $white;
  box-shadow: $box-shadow;
  height: 100%;
  padding: 1.5rem 1rem;
  gap: 1rem;
  @include media-breakpoint-up(sm) {
    display: block;
    text-align: center;
    padding: 2.5rem 2rem;
  }
  .icon {
  }
  .cont {
    @include media-breakpoint-up(sm) {
      margin-top: 2rem;
    }
    .title {
      font-size: 16px;
      margin: 0;
      line-height: 1.5;
      br {
        display: none;
      }
      @include media-breakpoint-up(sm) {
        font-size: 22px;
        text-align: center;
        br {
          display: inline;
        }
      }
    }
    .text {
      font-size: 13px;
      margin: 0;
      margin-top: 10px;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
        text-align: left;
        margin-top: 1rem;
        line-height: 1.75;
      }
    }
  }
}
