.sectionHeader {
  text-align: center;
  margin-bottom: 50px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 80px;
  }
  .bg {
    font-size: 70px;
    line-height: 1;
    opacity: 0.05;
    text-transform: uppercase;
    font-family: $font-family-en;
    margin-bottom: -79px;
    @include media-breakpoint-up(sm) {
      font-size: 100px;
      margin-bottom: -110px;
    }
  }
  .title {
    font-size: 30px;
    line-height: 1;
    padding: 30px 0;
    position: relative;
    @include media-breakpoint-up(sm) {
      font-size: 36px;
      padding: 45px 0;
    }
    &::before {
      position: absolute;
      bottom: 0;
      right: 50%;
      width: 20px;
      height: 4px;
      background-color: $primary;
      content: "";
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 20px;
      height: 4px;
      background-color: $primary;
      opacity: 0.5;
      content: "";
    }
  }
  &.left {
    @include media-breakpoint-up(sm) {
      text-align: left;
      .bg {
        text-indent: -0.5em;
      }
      .title {
        &::before {
          right: auto;
          left: 0;
        }
        &::after {
          left: 20px;
        }
      }
    }
  }
}
