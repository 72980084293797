#footer {
  background-color: #f7f7f7;
  padding: 15px 6vw;
  display: grid;
  grid-template:
    "gnav"
    "..." 30px
    "logo"
    "..." 30px
    "info"
    "..." 30px
    "copyright"
    / 1fr;
  @include media-breakpoint-up(sm) {
    padding: 45px 6vw 75px;
    grid-template:
      "logo ... gnav"
      ".... ... gnav" 30px
      "info ... gnav"
      "info ... copyright"
      / auto 1fr calc(140px * 3);
  }
  .logo {
    grid-area: logo;
    img {
      height: 53px;
      width: auto;
    }
  }
  .info {
    grid-area: info;
    p {
      font-size: 14px;
      color: $secondary;
    }
  }
  .gnav {
    grid-area: gnav;
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      font-size: 14px;
      @include media-breakpoint-up(sm) {
        flex-basis: 140px;
      }
      li {
        border-bottom: 1px solid $border-color;
        @include media-breakpoint-up(sm) {
          border-bottom: none;
          margin-bottom: 10px;
        }
        a {
          padding: 1rem 0;
          display: flex;
          align-items: center;
          gap: 5px;
          width: 100%;
          color: $body-color;
          text-decoration: none;
          &::before {
            font-family: "Font Awesome 5 Pro";
            content: "\f138";
            font-weight: 900;
            color: $primary;
            font-size: 90%;
          }
          @include media-breakpoint-up(sm) {
            display: inline-flex;
            width: auto;
            padding: 0;
          }
        }
      }
    }
  }
  .copyright {
    grid-area: copyright;
    text-align: center;
    font-size: 12px;
    color: $secondary;
    @include media-breakpoint-up(sm) {
      text-align: right;
    }
  }
}
