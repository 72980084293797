.movieBlock {
  text-align: center;
  padding: 36px 6vw;
  background: url("../img/bg_movie_left-sm.png") left center no-repeat,
    url("../img/bg_movie_right-sm.png") right center no-repeat;
  background-color: #f5f4f0;
  @include media-breakpoint-up(sm) {
    background: url("../img/bg_movie_left.png") left center repeat-y,
      url("../img/bg_movie_right.png") right center repeat-y;
    background-color: #f5f4f0;
    padding: 90px 6vw;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 6vw;
  }
}
