// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.9rem;
$input-btn-padding-x: 1.5rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 2px;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: rgba(
  $component-active-bg,
  $input-btn-focus-color-opacity
);
$input-btn-focus-blur: 0;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
  $input-btn-focus-color;

$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: 1.25rem;
$input-btn-padding-x-lg: 1.75rem;
$input-btn-font-size-lg: $font-size-base;

$input-btn-border-width: $border-width;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;
$btn-link-disabled-color: $gray-600;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;
// scss-docs-end btn-variables

.btn-more {
  border-radius: 60px !important;
  min-width: 180px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    background-image: url(../img/arrow_white.png);
    height: 8px;
    width: 12px;
    background-position: top;
    background-repeat: no-repeat;
    // background-size: contain;
    vertical-align: middle;
    margin-left: 10px;
    transition: $transition-base;
  }
  &.btn-light {
    &::after {
      background-image: url(../img/arrow_orange_yoko.png);
    }
  }
  &:hover {
    &::after {
      transform: translateX(2px);
    }
  }
}
// .btn-more2 {
//   min-width: 170px;
//   white-space: nowrap;
//   font-weight: bold !important;
//   border-radius: 60px !important;
//   display: inline-flex !important;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   padding: 15px 0 !important;
//   @include media-breakpoint-up(sm) {
//     min-width: 240px;
//     padding: 20px 0 !important;
//     font-size: 18px !important;
//   }
//   &::after {
//     position: absolute;
//     top: 50%;
//     right: 17px;
//     transform: translateY(-50%);
//     content: "";
//     background-image: url(../img/arrow_white.png);
//     height: 8px;
//     width: 12px;
//     background-position: top;
//     background-repeat: no-repeat;
//     // background-size: contain;
//     vertical-align: middle;
//     margin-left: 10px;
//     transition: $transition-base;
//     @include media-breakpoint-up(sm) {
//       right: 30px;
//     }
//   }
//   &.btn-light {
//     &::after {
//       background-image: url(../img/arrow_orange_yoko.png);
//     }
//   }
//   &:hover {
//     &::after {
//       transform: translateX(2px);
//     }
//   }
// }
.wp-block-button__link {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  background-color: $primary !important;
  color: $white;
  transition: $btn-transition;
  &::after {
    content: "";
    background-image: url(../img/arrow_white.png);
    height: 8px;
    width: 12px;
    background-position: top;
    background-repeat: no-repeat;
    // background-size: contain;
    vertical-align: middle;
    margin-left: 10px;
    transition: $transition-base;
  }
  &:hover {
    color: $white !important;
    background-color: shade-color($primary, 10%) !important;
    &::after {
      transform: translateX(2px);
    }
  }
}
.btn-nextprev {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 60px !important;
  gap: 1rem;
  position: relative;
  min-width: 150px;
  @include media-breakpoint-up(sm) {
    min-width: 200px;
  }
  .icon {
    color: $primary;
    &.left {
      position: absolute;
      top: calc(50% - 0.75em);
      left: 1rem;
    }
    &.right {
      position: absolute;
      top: calc(50% - 0.75em);
      right: 1rem;
    }
  }
}
