.newsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    border-bottom: 1px solid $border-color;
    a {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 5px;
      font-size: 14px;
      text-decoration: none;
      transition: $transition-base;
      padding: 1rem 0;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
        flex-direction: row;
        align-items: flex-start;
        gap: 15px;
        padding: 1.5rem 0;
      }
      .date {
        color: $gray-600;
        font-family: $font-family-serif;
      }
      .title {
        flex: 1;
        color: $body-color;
      }
      &:hover {
        background-color: $gray-100;
      }
    }
  }
}
