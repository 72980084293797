.serviceDigest {
  background-color: #f5f4f0;
  padding: 60px 0;
  position: relative;
  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
  .digest {
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(sm) {
      .cont {
        padding-bottom: 120px;
      }
    }
  }
  .brandContainer {
    position: relative;
    z-index: 2;
  }
  .summaryWrap {
    margin-top: 45px;
    @include media-breakpoint-up(sm) {
      margin-top: -120px;
      padding-top: 60px;
      padding-left: 60px;
      background-color: #f5f4f0;
    }
  }
}
