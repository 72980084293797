.brandTable {
  display: table;
  width: 100%;
  font-size: 14px;
  border-collapse: separate;
  border-spacing: 10px 0;
  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }
  dl {
    display: table-row;
    margin: 0;
    padding: 0;
    dt {
      display: table-cell;
      margin: 0;
      padding: 20px 0;
      padding-right: 20px;
      border-top: 1px solid $primary;
      white-space: nowrap;
      width: 1%;
      @include media-breakpoint-up(sm) {
        min-width: 200px;
      }
    }
    dd {
      display: table-cell;
      margin: 0;
      padding: 20px 0;
      padding-left: 20px;
      border-top: 1px solid rgba($body-color, 0.2);
    }
    &:last-child {
      dt {
        border-bottom: 1px solid $primary;
      }
      dd {
        border-bottom: 1px solid rgba($body-color, 0.2);
      }
    }
  }
  &.responsive {
    @include media-breakpoint-down(sm) {
      display: block;
      dl {
        display: block;
        dt {
          display: block;
          padding: 15px 0;
          width: 100%;
        }
        dd {
          display: block;
          padding: 15px 0;
          border-top: 1px dashed rgba($body-color, 0.2);
          width: 100%;
        }
        &:last-child {
          dt {
            border-bottom: 0;
          }
          dd {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
