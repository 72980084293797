.midashi2 {
  font-size: 16px;
  font-family: $font-family-sans-serif;
  font-weight: bold;
  padding: 1rem;
  line-height: 1.25;
  background-color: $weaken;
  color: $white;
  margin-bottom: 20px;
}
