.pageTopBtn {
  width: 50px;
  height: 50px;
  background-color: $primary;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: $zindex-fixed;
  transition: $transition-base;
  opacity: 0;
  &.sclolled {
    opacity: 1;
  }
  &:hover {
    background-color: shade-color($primary, 10%);
    color: $white;
  }
}
