.topNews {
  background-color: $gray-200;
  padding: 20px 6vw;
  display: grid;
  grid-template:
    "body ... footer"
    / 1fr 15px auto;
  @include media-breakpoint-up(sm) {
    grid-template:
      "header body ... footer"
      / auto 1fr 15px auto;
  }
  .header {
    display: none;
    @include media-breakpoint-up(sm) {
      grid-area: header;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 1.5rem;
      border-right: 1px solid $gray-400;
      .title {
        font-size: 15px;
        line-height: 1;
        margin: 0;
        font-family: $font-family-sans-serif;
        font-weight: bold;
      }
    }
  }
  .body {
    grid-area: body;
    @include media-breakpoint-up(sm) {
      padding-left: 1.5rem;
    }
    .news {
      text-decoration: none;
      font-size: 14px;
      display: grid;
      grid-template:
        "date .... new ..."
        ".... .... ... ..." 5px
        "text text text text"
        / auto 10px auto 1fr;
      @include media-breakpoint-up(sm) {
        grid-template:
          "date  .... new ... text"
          / auto 15px auto 15px 1fr;
      }
      .date {
        grid-area: date;
        color: $gray-600;
        font-family: $font-family-serif;
        align-self: center;
      }
      .new {
        grid-area: new;
        display: inline-block;
        background-color: $danger;
        color: $white;
        padding: 1px 5px;
        font-size: 12px;
        align-self: center;
        font-family: $font-family-serif;
      }
      .text {
        grid-area: text;
        text-decoration: underline;
        color: $gray-800;
        align-self: center;
      }
    }
  }
  .footer {
    grid-area: footer;
    display: flex;
    justify-content: center;
    align-items: center;
    .dotMoreLink {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      text-decoration: none;
      .text {
        color: $gray-600;
        display: none;
        font-size: 14px;
        font-weight: bold;
        transition: $transition-base;
        @include media-breakpoint-up(sm) {
          display: block;
        }
      }
      .icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $white;
        color: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        transition: $transition-base;
        @include media-breakpoint-up(sm) {
          width: 24px;
          height: 24px;
          font-size: 18px;
        }
      }
      &:hover {
        .text {
          color: $body-color;
        }
        .icon {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}
