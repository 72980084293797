.responsiveTable {
  @include media-breakpoint-up(sm) {
    display: table;
  }
  .tr {
    padding: 5px 0;
    @include media-breakpoint-up(sm) {
      display: table-row;
      padding: 0;
    }
    .th {
      font-weight: bold;
      @include media-breakpoint-up(sm) {
        display: table-cell;
        vertical-align: top;
        padding: 20px 0;
        padding-right: 1rem;
        white-space: nowrap;
      }
    }
    .td {
      @include media-breakpoint-up(sm) {
        display: table-cell;
        vertical-align: baseline;
        padding: 5px 0;
      }
    }
  }
}
