// scss-docs-start breadcrumb-variables
$breadcrumb-font-size: 12px;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: 0.5rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: null;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-400;
$breadcrumb-divider: quote(">");
$breadcrumb-divider-flipped: $breadcrumb-divider;
$breadcrumb-border-radius: null;
// scss-docs-end breadcrumb-variables
.breadcrumb {
  a {
    color: $body-color;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }
}
