.tamainews {
  padding: 32px 0 60px;
  @include media-breakpoint-up(sm) {
    padding: 45px 0 80px;
  }
  @include media-breakpoint-up(lg) {
    padding: 65px 0 100px;
  }
  .leadText {
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 32px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 56px;
      text-align: center;
    }
  }
  .categoryBox {
    padding: 24px 0;
    border-top: solid 1px #dedede;
    border-bottom: solid 1px #dedede;
    margin-bottom: 40px;
    @include media-breakpoint-up(sm) {
      padding: 32px 0;
      margin-bottom: 56px;
    }
    .categoryGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      flex-wrap: wrap;
      @include media-breakpoint-up(sm) {
        gap: 10px;
        flex-wrap: nowrap;
      }
      .categorySingle {
        flex-basis: calc((100% - 15px) / 2);
        display: block;
        padding: 10px 0;
        font-family: "Noto Serif JP", serif;
        font-size: 14px;
        white-space: nowrap;
        line-height: 1em;
        text-decoration: none;
        text-align: center;
        transition: $transition-base;
        @include media-breakpoint-up(sm) {
          flex-basis: 22%;
          padding: 13px 0;
          font-size: 15px;
        }
        &.small {
          @include media-breakpoint-up(sm) {
            flex-basis: 12%;
          }
        }
        &.event {
          color: #cb0c0c;
          border: solid 1px #cb0c0c;
          &:hover {
            color: $white;
            background-color: #cb0c0c;
          }
        }
        &.management {
          color: #80b71d;
          border: solid 1px #80b71d;
          &:hover {
            color: $white;
            background-color: #80b71d;
          }
        }
        &.clinical {
          color: #e59a48;
          border: solid 1px #e59a48;
          &:hover {
            color: $white;
            background-color: #e59a48;
          }
        }
        &.product {
          color: #5da2da;
          border: solid 1px #5da2da;
          &:hover {
            color: $white;
            background-color: #5da2da;
          }
        }
        &.all {
          color: $white;
          background-color: #888888;
          border: solid 1px #cccccc;
          &:hover {
            background-color: $body-color;
            border: solid 1px $body-color;
          }
        }
      }
    }
  }
}

