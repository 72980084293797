$headerHeightSM: 70px;
$headerHeightLG: 110px;

#header {
  width: 100%;
  height: $headerHeightSM;
  display: flex;
  // flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: $zindex-fixed;
  top: 0;
  left: 0;

  @include media-breakpoint-up(sm) {
    height: $headerHeightLG;
    padding: 30px 40px;
    align-items: flex-start;
  }

  .logo {
    padding: 0 20px;

    @include media-breakpoint-up(lg) {
      padding: 5px 20px;
    }

    img {
      height: 36px;
      width: auto;

      @include media-breakpoint-up(sm) {
        height: 45px;
      }
    }
  }

  .gnav {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // flex-wrap: wrap;
      // gap: calc(10 /1400 * 100vw);
      gap: 1rem;
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.2rem;
        margin-right: 1.5rem;
        // font-size: min(calc(15 / 1400 * 100vw), 15px);
        font-size: 15px;
        li {
          a {
            color: $body-color;
            text-decoration: none;
          }
        }
        .subnav {
          position: relative;
          .subnavlink {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            transition: $transition-base;
            &::after {
              transform: rotate(0);
              font-size: 15px;
              font-weight: normal;
              align-self: center;
              font-family: "Font Awesome 5 Pro";
              content: "\f078";
              color: $body-color;
              transition: $transition-base;
            }
          }
          .gnav-sub {
            display: none;
            width: 165px;
            position: absolute;
            top: 0;
            left: -50%;
            padding: 50px 0 0;
            margin: 0;
            list-style-type: none;
            @include media-breakpoint-up(xl) {
              padding: 35px 0 0;
            }
            > li {
              background-color: $white;
              transition: $transition-base;
              position: relative;
              width: 100%;
              > a {
                position: relative;
                color: #333333;
                text-decoration: none;
                padding: 10px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 2em;
                font-size: 15px;
                font-weight: normal;
                &::after {
                  content: "";
                  background-image: url(../img/arrow_orange_yoko.png);
                  height: 8px;
                  width: 12px;
                  background-position: top;
                  background-repeat: no-repeat;
                  vertical-align: middle;
                }
              }
              &:hover {
                background-color: $body-color;
                a {
                  color: $white;
                }
              }
            }
          }
          &:hover {
            .subnavlink::after {
              transform: rotate(180deg);
            }
            .gnav-sub {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: baseline;
              gap: 2px;
            }
          }
        }
      }
    }
  }

  // .btn-more {
  //   min-width: 170px;
  //   width: 170px;
  // }
}
.bottomBtn {
  display: none;
  position: fixed;
  z-index: $zindex-fixed;
  top: 0;
  right: 0;

  @include media-breakpoint-up(lg) {
    top: $headerHeightLG - 30px;
    padding: 1rem 40px;
    display: flex;
    align-items: flex-end;
    // width: 100%;
  }
}
